import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { CountriesFacade } from '@app/store/countries';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CountriesPreloadGuard {
    constructor(private countriesFacade: CountriesFacade) {}

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        this.countriesFacade.getCountries();
        return this.countriesFacade.loaded$.pipe(
            take(1),
            map(() => true),
        );
    }
}
