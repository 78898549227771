import { Injectable } from '@angular/core';
import { UrlTree, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserFacade } from '@app/store/user';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UserLoggedInGuard {
    constructor(
        private userFacade: UserFacade,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.userFacade.loaded$.pipe(
            take(1),
            map((loaded) => {
                if (loaded === false) {
                    return this.router.createUrlTree(['/account'], {
                        queryParams: {
                            ref: state.url,
                        },
                    });
                }
                return true;
            }),
        );
    }
}
